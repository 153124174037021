import cookie from '../plugins/jquery.cookie.js';
import slick from '../plugins/slick.js';
import matchHeight from '../plugins/jquery.matchHeight.js';

(function($) {

// Use this variable to set up the common and page specific functions. If you
// rename this variable, you will also need to rename the namespace below.
var Base = {
  // All pages
  common: {
    init: function() {
      /////
      // JavaScript to be fired on all pages
      /////

      // Search
      $('.search-toggle').click(function(e){
        e.preventDefault();
        $('#search-popup').toggleClass('active');
        $('#search-input').focus();
        $(window).scrollTop(0);
      });
      $('.close-search').click(function(){
        $('#search-popup').removeClass('active');
      });

      // Content with Background
      function cwb_angles() {
        var $angleheight;
        if ($(window).width() < 768) {
          $angleheight = 100;
        } else if ($(window).width() < 1270) {
          $angleheight = 180;
        } else {
          $angleheight = 260;
        }
        $('.content_with_background').each(function(){
          var $this = $(this).children('.background-image'),
              $height = $this.height(),
              $angle = parseFloat($angleheight / $height) * 100;
              console.log($angle);
          $this.css({'clip-path': 'polygon(50% ' + $angle + '%, 100% 0, 100% 100%, 0 100%, 0 0)'});
        });
      }

      // Commentary Callout
      $('.commentary_callout').each(function(){
        var $distance = $(this).find('.content-box').outerHeight() - 90;
        $(this).find('.commentary-post').css('margin-top', '-' + $distance + 'px');
      });

      // Latest Commentary Insight
      $('.latest_commentary_insight').each(function(){
        var $distance = $(this).find('.content-box').outerHeight() - 90;
        $(this).find('.commentary-post').css('margin-top', '-' + $distance + 'px');
      });

      // Pagination
      $('#pagination').on('click', 'a', function(e){
        e.preventDefault();
        $(this).remove();
        $('#ajax-loader').load($(this).attr('href') + ' #ajax-posts>*', function(){
          $('#ajax-posts').append($('#ajax-loader').html());
        });
        $('#pagination').load($(this).attr('href') + ' #pagination>*');
      });

      // Anchor
      $('a').on('click', function(e){
        var $href = $(this).attr('href');
        if ($href.match('^#') && !$(this).hasClass('accordion-tab')) {
          e.preventDefault();
          $('html, body').stop().animate({
              scrollTop: $($href).offset().top
          });
        }
      });

      // Video popups
      $('a').on('click', function(e){
        var $target = $(this).attr('href');
        if ($target.toLowerCase().indexOf("vimeo") >= 0) {
          e.preventDefault();
          var $n = $target.lastIndexOf('/');
          var $vid = $target.substring($n + 1);
          $('.popup-content').html('<iframe src="https://player.vimeo.com/video/' + $vid + '/?autoplay=1&api=1" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
          $('.video-popup-wrapper').addClass('active');
          $('body, html').addClass('body-noscroll');
        }
        if ($target.toLowerCase().indexOf("youtu") >= 0) {
          e.preventDefault();
          var $y = $target.lastIndexOf('=');
          var $yid = $target.substring($y + 1);
          $('.popup-content').html('<iframe src="https://www.youtube.com/embed/' + $yid + '/?autoplay=1" width="500" height="281" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
          $('.video-popup-wrapper').addClass('active');
          $('body, html').addClass('body-noscroll');
        }
      });
      $('.close-popup').on('click', function(){
        $('.video-popup-wrapper').removeClass('active');
        $('.popup-content').html('');
        $('body, html').removeClass('body-noscroll');
      });

      // Mobile Nav
      $('#mobile-nav-toggle').on('click', function(){
        var $navheight = $('#mobile-navigation').height() + 170;
        $('body, html').toggleClass('nav-locked');
        $('#mobile-navigation-wrapper').toggleClass('active');
        $(this).toggleClass('active');
      });

      // Match Height Helper Class
      $('.match-height-row').matchHeight({
        byRow: true,
        property: 'min-height'
      });
      $('.match-height').matchHeight({
        byRow: false,
        property: 'min-height'
      });

      // Resize for Match-Height and Slick-Slider glitch fix
      $(window).bind('load', function(){
        $(window).trigger('resize');
        setTimeout(function(){ $(window).trigger('resize'); }, 500);
      });

      // On initial-load, load-complete, and window resize  
      cwb_angles();
      $(window).bind('load', function(){
        cwb_angles();
      });
      var resizeTimer;
      $(window).on('resize', function(e) {
        clearTimeout(resizeTimer);
        resizeTimer = setTimeout(function() {
          cwb_angles();
        }, 250);
      });

      // Hero Animations
      setTimeout(function(){
        $('.background-image').addClass('visible');
      }, 100);
      setTimeout(function(){
        $('.page-title').addClass('visible');
      }, 150);
      setTimeout(function(){
        $('.page-subtitle').addClass('visible');
      }, 300);
    }
  },
  // Home page
  home: {
    init: function() {
      // JavaScript to be fired on the home page

      setTimeout(function(){
        $('.boxes-wrapper .box:first-child').addClass('visible');
      }, 500);
      setTimeout(function(){
        $('.boxes-wrapper .box:nth-child(2)').addClass('visible');
      }, 700);
      setTimeout(function(){
        $('.boxes-wrapper .box:last-child').addClass('visible');
      }, 900);
      if ($(window).width() > 767) {
        setTimeout(function(){
          var $distance = $('.boxes-wrapper .box:last-child').offset().top - $('.boxes-wrapper .box:first-child').offset().top - $('.boxes-wrapper .box:first-child').outerHeight() - 30;
          $('.boxes-wrapper .box:last-child').css('margin-top', '-' + $distance + 'px');
        }, 50);
      }
    }
  }
};

// The routing fires all common scripts, followed by the page specific scripts.
// Add additional events for more control over timing e.g. a finalize event
var UTIL = {
  fire: function(func, funcname, args) {
    var namespace = Base;
    funcname = (funcname === undefined) ? 'init' : funcname;
    if (func !== '' && namespace[func] && typeof namespace[func][funcname] === 'function') {
      namespace[func][funcname](args);
    }
  },
  loadEvents: function() {
    UTIL.fire('common');

    $.each(document.body.className.replace(/-/g, '_').split(/\s+/),function(i,classnm) {
      UTIL.fire(classnm);
    });
  }
};

$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.